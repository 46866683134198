import Vue from "vue";
import { antd } from "./load-antd/antd";
import App from "./App.vue";
import "./assets/icons/iconfont/iconfont.css";
import "./assets/less/common.less";
import router from "./router/layout"; // 标准版路由
import routerNew from "./router/index"; // 升级版路由
import store from "./store";
import "./utils/fetch";
import { dateFormat, moneyFormat } from "./utils/global";
import { recordOpreat } from "./utils/screensaver";
import QmpButton from "./components/common/QmpButton";
import QmpLayout from "./components/common/QmpLayout";

import VConsole from "vconsole/dist/vconsole.min.js";
import { speak } from "./utils/hardware";
// console插件
if (process.env.NODE_ENV == "development") {
  //eslint-disable-next-line
  let vConsole = new VConsole();
  localStorage.setItem("vConsole_switch_x", 80);
  localStorage.setItem("vConsole_switch_y", 1380);
}

Vue.config.productionTip = false;
antd(Vue);
// 日期格式化过滤器
Vue.filter("date", (value, format) => {
  return dateFormat(value, format);
});
// 金钱格式化过滤器
Vue.filter("money", value => {
  return moneyFormat(value);
});

// 全局公共组件
Vue.component("QmpButton", QmpButton);
Vue.component("QmpLayout", QmpLayout);

// 屏保：记录页面的操作时间
recordOpreat();

/**
 * 语音功能
 * @param {String} text 需要语音的文字
 */
Vue.prototype.$speak = speak;

const vm = new Vue({
  // 如果切换为新版或景区自定义首页，则采用新版路由
  // curstomIndex=true为阿克苏景区定制首页（坚哥需求） 2022-07-14
  router:
    localStorage.newVersion === "true" ||
    /curstomIndex=true/.test(location.search)
      ? routerNew
      : router,
  store,
  render: h => h(App)
}).$mount("#app");

export default vm;

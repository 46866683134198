export default {
  /**
   * @description 自助机详情修改
   */
  setMachineDetail(state, detail) {
    state.machineDetail = detail;
  },
  setMenuData(state, detail) {
    state.menuData = detail;
  }
};

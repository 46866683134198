export default {
  // setOrderDiscount(state, data) {
  //   state.merchantDiscountStr = data.merchantDiscountStr; //整单优惠类型（整单折扣 A）（会员折扣 B）（优惠券 C）（营销活动 D）
  //   state.discountId = data.discountId || "";
  //   state.couponNo = data.couponNo || "";
  //   state.marketingId = data.marketingId || ""; //优惠活动id,优惠券id
  //   state.merchantDiscountAmount = data.merchantDiscountAmount || 0; //订单折扣（整单优惠,会员折扣,优惠券,营销活动）共抵扣了多少钱
  //   state.customAmount = data.customAmount || ""; //整单折扣，自定义立减或折扣时，输入的值
  //   state.settingType = data.settingType || ""; //整单折扣类型：1立减 2折扣
  //   state.marketingMobile = data.marketingMobile || ""; //营销活动输入的会员手机号
  //   state.idCardNo = data.idCardNo || ""; //营销活动输入的身份证号
  // },
  // setIntegral(state, data) {
  //   state.integralPayPrice = data.integralPayPrice; //会员积分抵扣了多少钱
  //   state.integralPayNum = data.integralPayNum; //使用了多少会员积分
  // },
  // setMemberBalance(state, data) {
  //   state.memberBalancePrice = data.memberBalancePrice; //会员余额抵扣了多少钱
  // },
  setTouristInfo(state, data) {
    state.visitorsList = data.visitorsList; //出游人信息
    state.purchaserMemberInfo = data.purchaserMemberInfo; //购票人需要填的字段信息
  },
  setPage(state, data) {
    state.page += data;
  },
  setCartList(state, data) {
    state.cartList = data; //购物车列表
  },
  setShopItemByNum(state, { index, num }) {
    state.cartList.productItemList[index].buyNum = num;
  },
  // clearDiscount(state) {
  //   state.merchantDiscountStr = ""; //整单优惠类型（整单折扣 A）（会员折扣 B）（优惠券 C）（营销活动 D）
  //   state.customAmount = ""; //整单折扣，自定义立减或折扣时，输入的值
  //   state.settingType = ""; //整单折扣类型：1立减 2折扣
  //   state.discountId = ""; //非自定义时，整单折扣id
  //   state.merchantDiscountAmount = 0; //订单折扣（整单优惠,会员折扣,优惠券,营销活动）抵扣了多少钱
  //   state.couponNo = ""; //优惠券号
  //   state.marketingId = ""; //优惠活动id,优惠券id
  //   state.marketingMobile = ""; //营销活动输入的会员手机号
  //   state.idCardNo = ""; //营销活动输入的身份证号
  //   // state.memberBalancePrice = 0; //会员余额抵扣了多少钱
  //   state.integralPayPrice = 0; //会员积分抵扣了多少钱
  //   state.integralPayNum = 0; //使用了多少会员积分
  // },

  // 支付重构：订单折扣优惠（新增、更新）
  setOrderDiscountTextList(state, data) {
    state.discountTextList = [];
    state.discountTextList.push(data);
  },
  // 支付重构：订单折扣优惠（删除）
  removeOrderDiscountTextList(state) {
    state.discountTextList = [];
  },
  // 支付重构：积分抵扣（新增、更新）
  setIntegralDeduction(state, data) {
    state.integralTextList = [];
    state.integralTextList.push(data);
  },
  // 支付重构：积分抵扣（删除）
  removeIntegralDeduction(state) {
    state.integralTextList = [];
  },
  // 支付重构：清空所有优惠数据
  clearDiscount(state) {
    state.discountTextList = [];
    state.integralTextList = [];
  },
  // 支付完成后，需要告知支付页面的操作配置
  setEndOfPayConfig(state, data) {
    if (Object.prototype.toString.call(data) != "[object Object]") {
      throw new Error("设置支付完成配置vuex：传入的参数，只能是js对象");
    }
    state.endOfPayConfig = { ...state.endOfPayConfig, ...data };
  },
  // 更新isSubOrder
  updateIsSubOrder(state, isSubOrder) {
    state.isSubOrder = isSubOrder;
  },
  // 更新canSubOrder
  updateCanSubOrder(state, canSubOrder) {
    state.canSubOrder = canSubOrder;
  }
};

/**
 * 门票充值版块
 */

export default [
  {
    path: "/recharge",
    name: "recharge",
    component: () =>
      import(
        /* webpackChunkName: "recharge" */ "../../views/recharge/recharge.vue"
      )
  },
  // 可充值 列表
  {
    path: "/recharge-list",
    name: "recharge-list",
    meta: {
      rootUrl: "/recharge" // 右侧菜单根路由，用于右侧菜单高亮
    },
    component: () =>
      import(
        /* webpackChunkName: "recharge" */ "../../views/recharge/recharge-list.vue"
      )
  },
  // 门票充值的订单页面，和购票的订单页，为同一页面。
  // 这里增加门票充值订单的路由，指向buy-order页面，是为了高亮门票充值菜单按钮
  {
    path: "/recharge-order",
    name: "recharge-order",
    meta: {
      rootUrl: "/recharge" // 右侧菜单根路由，用于右侧菜单高亮
    },
    component: () =>
      import(/* webpackChunkName: "buy" */ "../../views/buy/buy-order.vue")
  }
];

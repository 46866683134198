import { login, authorize } from "../api/user";
import { findCurrentUserRelationMerchantList } from "../api/merchant";
import { encrypt } from "./crypt-aes";
import { isDev, localSN } from "./global";

let SN = ""; // 自助机SN系列号

// 登录接口
function loginInterface(resolve) {
  if (isDev) {
    SN = localSN;
  } else {
    SN = localStorage.getItem("SNCODE");
  }

  // 登录账号和密码均为：自助机SN序列号
  const { key, encryptedWord } = encrypt(SN);
  login({
    phone: SN,
    password: encryptedWord,
    passwordKey: key
  })
    .then(() => getMerchantId(resolve))
    .catch(() => resolve(false));
}

// 获取景区（商户）id接口
function getMerchantId(resolve) {
  findCurrentUserRelationMerchantList()
    .then(res => {
      // console.log(res);
      auth(res, resolve);
    })
    .catch(() => resolve(false));
}

// 授权接口
function auth(res, resolve) {
  // 景区id:目前先用：黄洋界-井冈山景区(自助机默认只会返回一条数据)
  const merchantId = res[0].merchantId;
  localStorage.setItem("merchantId", merchantId);
  authorize({
    merchantId, // 景区id
    channel: 8 // 自助机渠道
  })
    .then(() => {
      resolve(true);
    })
    .catch(() => resolve(false));
}

export default function getToken() {
  return new Promise(resolve => {
    loginInterface(resolve);
  });
}

import Vue from "vue";
import Vuex from "vuex";

import common from "./common";
import search from "./search";
import home from "./home";
import order from "./order";
import setting from "./setting";
import recharge from "./recharge";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    common,
    search,
    home,
    order,
    setting,
    recharge
  }
});

export default {
  // 门票查询、查到的门票列表数据
  setTicketLis({ commit }, data) {
    commit("ticketListMutation", data);
  },
  // 门票操作 - 退票按钮，查询到的数据
  setRefund({ commit }, data) {
    commit("refundMutation", data);
  }
};

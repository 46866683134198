/**
 * 会员路由
 */

export default [
  // 会员首页
  {
    path: "/member",
    name: "MemberIndex",
    component: () =>
      import(
        /* webpackChunkName: "new-member" */ "../../views/new/member/MemberIndex"
      )
  },
  {
    path: "/productList",
    name: "ProductList",
    component: () =>
      import(
        /* webpackChunkName: "new-member" */ "../../views/new/member/ProductList.vue"
      )
  }
];

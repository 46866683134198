/**
 * 查询路由
 */

export default [
  {
    path: "/search",
    name: "SearchIndex",
    component: () =>
      import(
        /* webpackChunkName: "new-search" */ "../../views/new/search/SearchIndex"
      )
  },
  {
    path: "/search/OrderDetail",
    name: "OrderDetail",
    component: () =>
      import(
        /* webpackChunkName: "new-search" */ "../../views/new/search/OrderDetail"
      )
  },
  {
    path: "/search/TicketDetail",
    name: "TicketDetail",
    component: () =>
      import(
        /* webpackChunkName: "new-search" */ "../../views/new/search/TicketDetail"
      )
  }
];

export default {
  memberId(state) {
    return state.member.id;
  },
  memberInfo(state) {
    return state.member;
  },
  // 是否显示打印进度弹窗
  printProcessDialog(state) {
    return state.printProcessDialog;
  },

  // 获取商户信息,子景区信息，header请求信息用
  getSaleMerchant(state) {
    let saleMerchant = "";
    if (state.saleMerchantId && state.saleMerchantName) {
      saleMerchant = `${state.saleMerchantId},${encodeURIComponent(
        state.saleMerchantName
      )}`;
    }
    return saleMerchant;
  }
};

<template>
  <div class="layout full">
    <banner class="layout-banner" v-if="!isDev"></banner>
    <div class="layout-main flex-end flex-item">
      <router-view class="flex-grow"></router-view>
      <right-menu></right-menu>
    </div>
    <!-- 全局遮罩层 mask -->
    <div class="all-mask" :class="{ hide: loading }">
      <div class="mask-cont">
        <!-- loading begin -->
        <div class="loadingio-spinner-spin-16iuedoa4a">
          <div class="ldio-u82rg5sled">
            <div><div></div></div>
            <div><div></div></div>
            <div><div></div></div>
            <div><div></div></div>
            <div><div></div></div>
            <div><div></div></div>
            <div><div></div></div>
            <div><div></div></div>
          </div>
        </div>
        <!-- loading end -->
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "@/components/common/banner";
import RightMenu from "@/components/common/right-menu";
import { isDev } from "./utils/global";
export default {
  name: "Layout",
  components: {
    Banner,
    RightMenu
  },
  props: {},
  data() {
    return {
      isDev // 是否开发模式
    };
  },
  computed: {
    loading() {
      return this.$store.state.common.loading;
    }
  },
  methods: {}
};
</script>
<style lang="less" scoped>
.layout {
  position: relative;
  left: 0;
  top: 0;
  .all-mask {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 8888;
    background-color: rgba(0, 0, 0, 0.3);
    .mask-cont {
      color: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .hide {
    display: block;
  }
}
.layout-main {
  height: calc(100vh - 320px); // 减去banner图高度
}

@keyframes ldio-u82rg5sled {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1.5, 1.5);
  }
  100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1);
  }
}
.ldio-u82rg5sled div > div {
  position: absolute;
  width: 18.12px;
  height: 18.12px;
  border-radius: 50%;
  background: #ffffff;
  animation: ldio-u82rg5sled 1s linear infinite;
}
.ldio-u82rg5sled div:nth-child(1) > div {
  left: 111.5px;
  top: 66.5px;
  animation-delay: -0.875s;
}
.ldio-u82rg5sled > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 120.56px 75.56px;
}
.ldio-u82rg5sled div:nth-child(2) > div {
  left: 98.5px;
  top: 98.5px;
  animation-delay: -0.75s;
}
.ldio-u82rg5sled > div:nth-child(2) {
  transform: rotate(45deg);
  transform-origin: 107.56px 107.56px;
}
.ldio-u82rg5sled div:nth-child(3) > div {
  left: 66.5px;
  top: 111.5px;
  animation-delay: -0.625s;
}
.ldio-u82rg5sled > div:nth-child(3) {
  transform: rotate(90deg);
  transform-origin: 75.56px 120.56px;
}
.ldio-u82rg5sled div:nth-child(4) > div {
  left: 34.5px;
  top: 98.5px;
  animation-delay: -0.5s;
}
.ldio-u82rg5sled > div:nth-child(4) {
  transform: rotate(135deg);
  transform-origin: 43.56px 107.56px;
}
.ldio-u82rg5sled div:nth-child(5) > div {
  left: 21.5px;
  top: 66.5px;
  animation-delay: -0.375s;
}
.ldio-u82rg5sled > div:nth-child(5) {
  transform: rotate(180deg);
  transform-origin: 30px 75.56px;
}
.ldio-u82rg5sled div:nth-child(6) > div {
  left: 34.5px;
  top: 34.5px;
  animation-delay: -0.25s;
}
.ldio-u82rg5sled > div:nth-child(6) {
  transform: rotate(225deg);
  transform-origin: 43.56px 43.56px;
}
.ldio-u82rg5sled div:nth-child(7) > div {
  left: 66.5px;
  top: 21.5px;
  animation-delay: -0.125s;
}
.ldio-u82rg5sled > div:nth-child(7) {
  transform: rotate(270deg);
  transform-origin: 75.56px 30px;
}
.ldio-u82rg5sled div:nth-child(8) > div {
  left: 98.5px;
  top: 34.5px;
  animation-delay: 0s;
}
.ldio-u82rg5sled > div:nth-child(8) {
  transform: rotate(315deg);
  transform-origin: 107.56px 43.56px;
}
.loadingio-spinner-spin-16iuedoa4a {
  width: 151px;
  height: 151px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-u82rg5sled {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  background-color: transparent;
}
.ldio-u82rg5sled div {
  box-sizing: content-box;
}
</style>

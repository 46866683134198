/**
 * 首页：取票版块
 */

export default [
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../../views/home/home.vue")
  },
  {
    path: "/tickes-list",
    name: "tickes-list",
    meta: {
      rootUrl: "/home" // 右侧菜单根路由，用于右侧菜单高亮
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../../views/home/tickes-list.vue")
  },
  {
    path: "/records",
    name: "records",
    meta: {
      rootUrl: "/home" // 右侧菜单根路由，用于右侧菜单高亮
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../../views/home/records.vue")
  }
];

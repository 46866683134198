import axios from "axios";
import { Message, Modal } from "ant-design-vue";
import store from "../store/index";
import getToken from "@/utils/get-token";

/**
 * @name: 全局AJAX请求类
 * @date: 2020-12-21
 * @author： 易远胜
 * @params: {
 *  url: 请求地址，
 *  params: get和delete方法的请求参数
 *  data: post/put/add方法的请求参数
 *  config: axios的config(可以覆盖实例默认配置)
 *  }
 */
class Http {
  constructor() {
    this.requestCount = 0; // 请求数量
    this._responseInterception(); // 响应拦截
  }
  // 是否有接口在刷新token
  #isRefresh = false;
  // token失效时，被挂起的请求
  #pendingReqList = [];
  // modal弹窗相关配置
  static hasModal = false; // 控制页面上，多个接口报错时只显示一个Modal弹窗
  static modalConfig(content) {
    return {
      title: "提示",
      centered: true,
      width: 580,
      content: content || "系统异常，请稍后重试",
      okText: "确认",
      onOk() {
        Http.hasModal = false;
      },
      onCancel() {
        Http.hasModal = false;
      }
    };
  }

  // 公共配置
  instance() {
    return {
      timeout: 60000,
      headers: {
        "open-d": localStorage.token || "",
        "Access-Control-Expose-Headers": "hello-qmp-one"
      }
    };
  }

  // 转换成URLSearchParams对象形式
  paramsSerializer(params) {
    let str = [];
    for (let key in params) {
      if (Array.isArray(params[key])) {
        str = str.concat(params[key].map(item => `${key}=${item}`));
      } else if (
        typeof params[key] === "string" ||
        typeof params[key] === "number" ||
        typeof params[key] === "boolean"
      ) {
        str.push(`${key}=${params[key]}`);
      }
    }
    return str.join("&");
  }

  // 添加数据
  add(url, data, config = {}) {
    return this.request({ url, method: "post", data, ...config }, "添加成功");
  }
  // 删除数据
  delete(url, params = {}, config = {}) {
    return this.request(
      { url, method: "delete", params, ...config },
      "删除成功"
    );
  }
  // 修改/编辑数据
  put(url, data, config = {}) {
    return this.request({ url, method: "put", data, ...config }, "修改成功");
  }
  // 更新数据-核销/开启/关闭/特殊操作等需要自定义提示语的需求
  update(url, data, config = {}) {
    return this.request({ url, method: "put", data, ...config });
  }
  // 获取数据
  get(url, params = {}, config = {}) {
    return this.request({
      url,
      method: "get",
      params,
      ...config,
      // `paramsSerializer` 是一个负责 `params` 序列化的函数
      paramsSerializer: this.paramsSerializer
    });
  }
  // 提交数据-需要自定义提示语的需求
  post(url, data = {}, config = {}) {
    return this.request({ url, method: "post", data, ...config });
  }

  // 通用请求方法
  request(config = {}, customMsg) {
    // 是否展示全局loading动画（全局遮罩层）
    if (!config.loading) {
      store.commit("common/updateLoading", true);
      this.requestCount++;
    }
    return new Promise((resolve, reject) => {
      axios
        .request({ ...this.instance(), ...config })
        .then(({ headers, data, status }) => {
          if (customMsg) {
            Message.config({ top: "800px" });
            Message.success(customMsg);
          }
          // 存储token
          if (headers["hello-qmp-one"]) {
            localStorage.setItem("token", headers["hello-qmp-one"]);
          }
          // 200：get成功；201：提交成功；204：修改/删除成功
          if (status === 200 || status === 201 || status === 204) {
            resolve(data);
            return;
          }
          // 200 <= status < 300（且非200,201,204）
          !Http.hasModal && Modal.error(Http.modalConfig(data.message));
          Http.hasModal = true;
          // 语音
          try {
            window.test.speak(data.message);
          } catch (error) {
            console.log("语音方法speak调用失败--fetch");
          }
          reject(data);
        })
        .catch(({ response }) => {
          // token需更新，静默处理
          if (response && response.isRefToken) {
            if (process.env.NODE_ENV == "development") {
              console.warn("token失效,正重新更新token");
            }
            reject(response); // 给token刷新失败时用
            return;
          }
          // 接口404
          if (response && response.status == 404) {
            const msg = `接口404：${response.config.url}，请检查该接口是否存在或者拼写是否正确`;
            !Http.hasModal && Modal.error(Http.modalConfig(msg));
            Http.hasModal = true;
            reject(response);
            return;
          }
          // 请求没发出，或者请求没有返回响应(断网)
          if (!response) {
            !Http.hasModal &&
              Modal.error(Http.modalConfig("网络中断，请检查网络"));
            Http.hasModal = true;
            reject({ msg: "请求没发出、或网络中断", status: 502 });
            return;
          }
          // 请求已发出，但接口报错
          if (response) {
            const msg =
              (response.data && response.data.message) ||
              "抱歉~系统异常了，请您稍后重试";
            try {
              window.test.speak(msg);
            } catch (error) {
              console.log("语音方法speak调用失败--fetch");
            }
            // 页面没有自己配置报错显示，才显示默认的弹窗提示语
            if (!config.customResult) {
              !Http.hasModal && Modal.error(Http.modalConfig(msg));
              Http.hasModal = true;
            }
            reject(response);
          }
        })
        .finally(() => {
          !config.loading && this.requestCount--;
          if (this.requestCount <= 0) {
            store.commit("common/updateLoading", false);
          }
        });
    });
  }

  // 响应拦截
  _responseInterception() {
    axios.interceptors.response.use(
      async res => {
        return res;
      },
      async err => {
        // SYS-ERROR403:权限不足；SYS-ERROR401：token失效
        // token失效，返回自定义响应，并且自动触发之前被挂起请求
        const { status } = err.response.data;
        if (status == "SYS-ERROR401" || status == "SYS-ERROR403") {
          if (!this.#isRefresh) {
            this.#isRefresh = true;
            // 记录当前触发刷新token的请求
            this.#pendingReqList.push(err.response.config);
            // 刷新token,并在token更新完成后，重新发起之前被挂起的请求
            if (await getToken()) {
              let conf = {};
              this.#pendingReqList.forEach(val => {
                conf.url = val.url;
                conf.method = val.method;
                if (
                  val.method.toUpperCase() == "POST" ||
                  val.method.toUpperCase() == "PUT"
                ) {
                  conf.data = val.data;
                } else {
                  conf.params = val.params;
                }
                this.request(conf);
              });
              this.#isRefresh = false;
              this.#pendingReqList = [];
            } else {
              // 刷新token失败
              this.#isRefresh = false;
              this.#pendingReqList = [];
              !Http.hasModal &&
                Modal.error(
                  Http.modalConfig("抱歉~我与后台失去了联络，您可以稍候重试嘛")
                );
              Http.hasModal = true;
            }
          } else {
            // 记录其他因token刷新被挂起请求
            this.#pendingReqList.push(err.response.config);
          }
          err.response.isRefToken = true;
          return Promise.reject(err);
        }

        // token不失效场景：返回默认响应
        return Promise.reject(err);
      }
    );
  }
}

export default new Http();

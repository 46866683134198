/**
 * 充值路由
 */

export default [
  {
    path: "/recharge",
    name: "RechargeIndex",
    component: () =>
      import(
        /* webpackChunkName: "new-recharge" */ "../../views/new/recharge/RechargeIndex"
      )
  }
];

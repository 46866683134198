/**
 * MS-硬件管理
 */
import http from "../utils/fetch"; // 公共请求方法
let prefix = "/api/iot"; // api前缀

// 人脸质量分析-胡汉杰
export const faceAnalyze = data => {
  return http.post(`${prefix}/face/analyze`, data);
};

// 创建人脸-胡汉杰
export const face = data => {
  return http.post(`${prefix}/face`, data);
};

// 【】校验并上传人脸-胡汉杰
export const faceAnalyzeUpload = data => {
  return http.post(`${prefix}/face_analyze/upload`, data);
};

// 【iot33】查询自助机详情- 刘院民
export const getMachineDetail = (data, config) => {
  return http.get(`${prefix}/iot/self/machine/detail`, data, config);
};

// 【iot34】查询自助机轮播图 - 刘院民
export const iotSelfMachineBanner = data => {
  return http.get(`${prefix}/iot/self/machine/banner`, data);
};

// 【iot35】自助机设备管理登录 - 刘院民
export const machineLogin = data => {
  return http.post(`${prefix}/iot/self/machine/login`, data);
};

// 【iot36】自助机剩余票数修改- 刘院民
export const updateMachineVotes = data => {
  return http.put(`${prefix}/iot/self/machine/votes`, data);
};

// 【iot44】打印门票后修改自助机票纸数量接口 - 刘院民
export const reduceIotSelfMachineVotes = data => {
  return http.update(`${prefix}/reduce/iot/self/machine/votes`, data);
};

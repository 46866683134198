export default {
  updateMember({ commit }, data) {
    commit("UpdateMember", data);
  },
  // 设置 支付完后，是否需要打印
  setPrintingRequired({ commit }, data) {
    commit("updatePrinting", data);
  },
  // 是否需要打印进度弹窗
  setPrintProcess({ commit }, data) {
    commit("setPrintProcess", data);
  }
};

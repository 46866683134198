/**
 *标准版路由配置
 * */
import Vue from "vue";
import VueRouter from "vue-router";
const path = require("path");
// 防止 Vue 重复点击相同路由报错
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};

Vue.use(VueRouter);

// 自动加载page文件夹下的，所有js文件
const reqContext = require.context("./page", false, /\.js$/);
const obj = {};
reqContext.keys().forEach(filePath => {
  // obj[filePath.replace(/^\.\/(.*)\.\w+$/, "$1")] = reqContext(filePath).default;
  obj[path.basename(filePath, ".js")] = reqContext(filePath).default;
});

import Layout from "../layout.vue"; // app-layout布局
import initSetting from "../views/init.vue"; //初始化硬件配置

const router = new VueRouter({
  routes: [
    {
      path: "/",
      component: Layout,
      // redirect: "/home",
      children: [
        ...obj.home,
        ...obj.buy,
        ...obj.recharge,
        ...obj.project,
        ...obj.search,
        ...obj.member,
        ...obj.setting
      ]
    },
    {
      path: "/initSetting",
      component: initSetting
    }
  ]
});

export default router;

/**
 *升级版路由配置
 * */
import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index"; // app-首页
import CustomIndex from "../views/CustomIndex"; // 定制-首页
import initSetting from "../views/init.vue"; //初始化硬件配置
const path = require("path");
// 防止 Vue 重复点击相同路由报错
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};

Vue.use(VueRouter);

// 自动加载page文件夹下的，所有js文件
const reqContext = require.context("./new", false, /\.js$/);
const obj = {};
reqContext.keys().forEach(filePath => {
  // obj[filePath.replace(/^\.\/(.*)\.\w+$/, "$1")] = reqContext(filePath).default;
  obj[path.basename(filePath, ".js")] = reqContext(filePath).default;
});

const router = new VueRouter({
  routes: [
    {
      path: "/",
      component: Index,
      children: [
        ...obj.take,
        ...obj.buy,
        ...obj.recharge,
        ...obj.search,
        ...obj.member,
        ...obj.setting
      ]
    },
    // 景区定制首页
    {
      path: "/CustomIndex",
      component: CustomIndex
    },
    {
      path: "/initSetting",
      component: initSetting
    },
    {
      path: "*",
      redirect: "/"
    }
  ]
});

export default router;

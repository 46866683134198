/**
 * 项目套餐版块
 * meta: {
      rootUrl: "/project" // 右侧菜单根路由，用于右侧菜单高亮
    },
 */

export default [
  {
    path: "/project",
    name: "project",
    component: () =>
      import(
        /* webpackChunkName: "project" */ "../../views/project/project.vue"
      )
  },
  // 项目套餐购买的订单页面，和购票的订单页，为同一页面。
  // 这里增加项目套餐的路由，指向buy-order页面，是为了高亮项目套餐菜单按钮
  {
    path: "/project-order",
    name: "project-order",
    meta: {
      rootUrl: "/project" // 右侧菜单根路由，用于右侧菜单高亮
    },
    component: () =>
      import(/* webpackChunkName: "buy" */ "../../views/buy/buy-order.vue")
  }
];

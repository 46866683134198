/**
 * 购票版块
 */

export default [
  {
    path: "/buy",
    name: "buy",
    component: () =>
      import(/* webpackChunkName: "buy" */ "../../views/buy/buy.vue")
  },
  // 登记游客信息
  {
    path: "/records-buying",
    name: "records-buying",
    meta: {
      rootUrl: "/buy" // 右侧菜单根路由，用于右侧菜单高亮
    },
    component: () =>
      import(/* webpackChunkName: "buy" */ "../../views/buy/records-buying.vue")
  },
  // 购买 票 订单
  {
    path: "/buy-order",
    name: "buy-order",
    meta: {
      rootUrl: "/buy" // 右侧菜单根路由，用于右侧菜单高亮
    },
    component: () =>
      import(/* webpackChunkName: "buy" */ "../../views/buy/buy-order.vue")
  },
  // 扫码支付 提示页
  {
    path: "/scan-pay",
    name: "scan-pay",
    component: () =>
      import(/* webpackChunkName: "buy" */ "../../views/buy/scan-pay.vue")
  },
  {
    path: "/paySuccess",
    name: "paySuccess",
    component: () =>
      import(
        /* webpackChunkName: "buy" */ "../../views/buy/components/pay-success.vue"
      )
  }
];

<!--
@name:布局组件
@author: 易远胜
@props: 传递参数保持跟antd Design一致，详情看本组件的props
@event: click 点击事件
-->

<template>
  <button
    @click="click"
    class="button"
    :disabled="disabled || loading"
    :class="{
      disabled: disabled || loading,
      block,
      small: size === 'small',
      'type-primary': type === 'primary'
    }"
    :style="{ 'min-width': width ? width : '' }"
  >
    <a-icon type="loading" class="mr-1x" v-if="loading" />
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "c-button",

  props: {
    // 按钮尺寸 small default
    disabled: {
      type: Boolean,
      default: false
    },
    // 幽灵属性，使按钮背景透明
    ghost: {
      type: Boolean,
      default: false
    },
    // 设置按钮载入状态 禁止点击
    loading: {
      type: Boolean,
      default: false
    },
    // 设置按钮大小，可选值为 small default默认
    size: {
      type: String,
      default: "default"
    },
    // 设置按钮类型，可选值为 primary default
    type: {
      type: String,
      default: "default"
    },
    // 块状按钮，将按钮宽度调整为其父宽度的选项
    block: {
      type: Boolean,
      default: false
    },
    // 按钮长度
    width: {
      type: String,
      default: ""
    }
  },

  methods: {
    click(e) {
      this.$emit("click", e);
    }
  }
};
</script>

<style scoped lang="less">
@import "../../assets/less/custom";
.button {
  min-width: 230px;
  height: 80px;
  border: 1px solid #666666;
  border-radius: 45px;
  padding: 20px;
  text-align: center;
  line-height: 38px;
  font-size: 24px;
  color: #666666;
  background: #fff;
  cursor: pointer;
  /*激活*/
  &:active {
    opacity: 0.5;
  }
  /*禁用*/
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  /*主题色*/
  &.type-primary {
    border-color: @primary;
    background: @primary;
    color: #ffffff;
  }

  /*幽灵*/
  &.ghost {
    background: transparent;
  }
  /*长条按钮*/
  &.block {
    display: block;
    width: 100%;
  }

  /*小型按钮*/
  &.small {
    height: 44px;
    min-width: 100px;
    padding: 6px;
    line-height: 30px;
    border-radius: 22px;
  }
}
</style>

<!--阿克苏景区定制首页-->
<template>
  <div class="custom-index">
    <div class="content">
      <!--滚动字幕-->
      <div class="move-text">
        <p>全国各族人民像石榴籽一样紧紧抱在一起！新疆天山托木尔景区欢迎您！</p>
      </div>
      <!--入口-->
      <a-row :gutter="16" class="card-list">
        <a-col :span="6">
          <div class="card-item" @click="linkTo(1)">
            <img src="../assets/images/custom-index/sujiyuding.png" alt="" />
          </div>
        </a-col>
        <a-col :span="6">
          <div class="card-item" @click="linkTo(2)">
            <img src="../assets/images/custom-index/jingqupiaowu.png" alt="" />
          </div>
        </a-col>
        <a-col :span="6">
          <div class="card-item" @click="linkTo(3)">
            <img src="../assets/images/custom-index/wensuhaoli.png" alt="" />
          </div>
        </a-col>
        <a-col :span="6">
          <div class="card-item" @click="linkTo(4)">
            <img src="../assets/images/custom-index/meishi.png" alt="" />
          </div>
        </a-col>
        <a-col :span="6">
          <div class="card-item" @click="linkTo(5)">
            <img
              src="../assets/images/custom-index/mingrensheying.png"
              alt=""
            />
          </div>
        </a-col>
        <a-col :span="6">
          <div class="card-item" @click="linkTo(6)">
            <img
              src="../assets/images/custom-index/jingdiangonglue.png"
              alt=""
            />
          </div>
        </a-col>
        <a-col :span="6">
          <div class="card-item" @click="linkTo(7)">
            <img src="../assets/images/custom-index/zhinengdaoyou.png" alt="" />
          </div>
        </a-col>
        <a-col :span="6">
          <div class="card-item" @click="linkTo(8)">
            <img src="../assets/images/custom-index/tingchejiayou.png" alt="" />
          </div>
        </a-col>
      </a-row>
    </div>

    <footer class="flex-between footer-fixed">
      <div>
        <p>编号: 001</p>
        <p class="mt-x">位置：游客中心1楼</p>
      </div>
      <div class="text-c">
        <p class="font-l">新疆义德创智科技有限公司</p>
        <p class="mt-x">
          技术支持：深圳市赛义德（汇利斯 通）信息技术有限公司（19129308658）
        </p>
      </div>
      <div class="text-c">
        <p>{{ time | date }}</p>
        <p class="mt-x">{{ time | date("week") }} {{ time | date("hh:mm") }}</p>
      </div>
    </footer>

    <!--集宿预定小程序码-->
    <a-modal title="请扫码" centered v-model="showAppCode" :footer="null">
      <div class="text-c">
        <img
          class="app-code"
          src="../assets/images/custom-index/sujiyuding-app-code.jpg"
          alt=""
        />
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "CustomIndex",

  data() {
    return {
      showAppCode: false,
      time: 0, // 时间
      timer: null // 定时器
    };
  },

  created() {
    this.getTime();
    this.timer = setInterval(this.getTime, 60000);
  },

  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },

  methods: {
    getTime() {
      this.time = new Date().getTime();
    },
    // 跳转到
    linkTo(i) {
      switch (i) {
        // 宿集预定
        case 1:
          this.showAppCode = true;
          break;
        // 景区票务
        case 2:
          localStorage.setItem("newVersion", "true");
          this.$router.push("/");
          break;
        // 温宿好礼
        case 3:
          location.href =
            "https://yjy.elvyoo.com/h5/aks/pageweb/pages/wshl/index";
          break;
        // 美食
        case 4:
          location.href =
            "https://yjy.elvyoo.com/h5/aks/pageweb/pages/eat/index";
          break;
        // 名人摄影
        case 5:
          location.href =
            "https://yjy.elvyoo.com/h5/aks/pageweb/pages/photograph/index";
          break;
        // 景点攻略
        case 6:
          location.href =
            "https://yjy.elvyoo.com/h5/aks/pageweb/pages/travelguide/index";
          break;
        // 智能导游
        case 7:
          location.href =
            "https://yjy.elvyoo.com/h5/aks/pageweb/pages/guide/index";
          break;
        // 停车加油
        case 8:
          location.href =
            "https://yjy.elvyoo.com/h5/aks/pageweb/pages/parking/index";
          break;
      }
    }
  }
};
</script>

<style scoped lang="less">
.custom-index {
  width: 100%;
  height: 100%;
  padding: 0 16px;
  background: url("../assets/images/custom-index/custom-index.png") no-repeat;
  background-size: 100% 100%;

  .content {
    padding-top: 64%;

    .move-text {
      margin-bottom: 32px;
      overflow-x: hidden;
      @keyframes move {
        from {
          transform: translateX(0px);
        }
        to {
          transform: translateX(-200vw);
        }
      }
      > p {
        width: 200vw;
        padding-left: 100vw;
        font-size: 32px;
        font-weight: bold;
        white-space: nowrap;
        color: #ff637b;
        animation: move 16s linear infinite;
      }
    }
    .card-list {
      .card-item {
        margin-top: 8px;
        cursor: pointer;
        &:active {
          opacity: 0.7;
        }
        > img {
          width: 100%;
        }
      }
    }
  }

  .app-code {
    width: 200px;
    height: 200px;
  }

  .footer-fixed {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 12px 16px;
    font-size: 14px;
    background-image: linear-gradient(to bottom, #8dedff, #01d3fc);
    color: #fff;
  }
}
</style>

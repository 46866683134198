<!--
@name:布局组件
@author: 易远胜
@props: title 导航栏标题
@slot: {
  #header: 顶部插槽
  slot： 默认插槽，中间内容
  #footer: 底部插槽
}
-->
<template>
  <div class="flex-between flex-col layout" :style="{ background: bgColor }">
    <header class="flex-shrink res">
      <slot name="header">
        <qmp-nav-bar :title="title" v-if="title" />
      </slot>
    </header>

    <section class="flex-grow content res">
      <slot></slot>
    </section>

    <!--底部-->
    <footer class="flex-shrink res bd-t">
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script>
import QmpNavBar from "./QmpNavBar";
export default {
  name: "QmpLayout",

  components: { QmpNavBar },

  props: {
    // 导航栏标题
    title: {
      type: String,
      default: ""
    },
    // 默认背景颜色 不传则为父级背景色
    bgColor: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped lang="less">
.layout {
  /*height: 100%;*/
  height: calc(100vh - 320px);
  .content {
    overflow: auto;
  }
}
</style>

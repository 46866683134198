export default {
  // 门票列表赋值
  ticketListMutation(state, data) {
    state.ticketList = data;
  },
  // 门票操作 - 退票按钮，查询到的数据
  refundMutation(state, data) {
    state.refund = data;
  }
};

/**
 * @name:MS-用户中心接口-3.0
 **/

import http from "../utils/fetch";
const prefix = "/api/user";

/**
 *用户登录授权
 */
// user001-用户登录-云玉峰
export function login(data) {
  return http.post(`${prefix}/login`, data);
}
// user002-用户授权-云玉峰
export function authorize(data) {
  return http.post(`${prefix}/authorize`, data);
}

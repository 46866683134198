export default {
  loading: false, //全局的loading
  member: {
    id: ""
  }, //会员信息
  printingRequired: true, // 支付完后，是否需要打印
  printProcessDialog: false, // 是否显示打印进度弹窗

  // 选中的子景区信息
  saleMerchantId: localStorage.getItem("saleMerchantId") || "", // 销售商户Id
  saleMerchantName: localStorage.getItem("saleMerchantName") || "" // 销售商户名称
};

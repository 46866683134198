/**
 * 会员充值版块
 * meta:{rootUrl: "根路由"} // 右侧菜单根路由，用于右侧菜单高亮
 */

export default [
  {
    path: "/member",
    name: "member",
    component: () =>
      import(/* webpackChunkName: "member" */ "../../views/member/member.vue")
  },
  // 可充值的会员套餐列表
  {
    path: "/package-list",
    name: "package-list",
    meta: {
      rootUrl: "/member" // 右侧菜单根路由，用于右侧菜单高亮
    },
    component: () =>
      import(
        /* webpackChunkName: "member" */ "../../views/member/package-list.vue"
      )
  },
  // 会员充值套餐订单
  {
    path: "/member-order",
    name: "member-order",
    meta: {
      rootUrl: "/member"
    },
    component: () =>
      import(
        /* webpackChunkName: "member" */ "../../views/member/member-order.vue"
      )
  }
];

/**
 * 屏保
 * @param {Object} vm: Vue的实例
 */
export function screensaver(vm) {
  if (intervalTimer) {
    clearInterval(intervalTimer);
  }
  const obj = localStorage.getItem("screensaver");
  const time = JSON.parse(obj).timeOfScreenSaver * 1000; // 后台设置的屏保时间
  // let loopTime = 30000; // 每间隔30秒，检测页面是否操作
  let intervalTimer = null; // 定时器

  // // 若后台设置的屏保时间小于30秒，轮询时间改为
  // if (time < loopTime) {
  //   loopTime = time
  // }

  function checkTimeout() {
    const currentTime = new Date().getTime(); // 更新当前时间
    const lastTime = localStorage.getItem("lastTime");
    console.log(
      "屏保定时器:",
      "当前时间：",
      currentTime,
      "最近操作时间:",
      lastTime,
      "两者相减相差：",
      currentTime - lastTime
    );
    // 超过设定时间，未操作
    if (currentTime - lastTime > time) {
      console.log(`${time / 1000}秒未操作，跳转到屏保页面`);
      intervalTimer && clearInterval(intervalTimer);
      // console.log(vm);
      vm.$router.push({ path: "/initSetting", query: { screen: "1" } });
    }
  }

  // 定时器：间隔time秒检测是否未操作页面
  intervalTimer = setInterval(checkTimeout, time);
}

/**
 * 记录页面的操作时间
 */
export function recordOpreat() {
  window.onload = function() {
    window.document.onmousedown = function() {
      const cime = new Date().getTime(); // 更新当前时间
      const lTime = localStorage.getItem("lastTime");
      // 只有当操作间隔10秒，才更新一次。不是每次点击，都更新一次
      if (cime - lTime >= 10000) {
        // console.log("点击了屏幕***，并且距离上次点击，超过10秒");
        // 更新最新的操作时间
        localStorage.setItem("lastTime", new Date().getTime());
      } else {
        // console.log("点击了屏幕***，但距离上次点击，还没超过10秒----------");
      }
    };
  };
}

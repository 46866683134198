/**
 * 取票路由
 */

export default [
  // 取票首页
  {
    path: "/take",
    name: "TakeIndex",
    component: () =>
      import(/* webpackChunkName: "new-buy" */ "../../views/new/take/TakeIndex")
  },
  // 取票列表
  {
    path: "/take/TakeList",
    name: "TakeList",
    component: () =>
      import(/* webpackChunkName: "new-buy" */ "../../views/new/take/TakeList")
  }
];

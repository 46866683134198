/**
 * 门票查询版块
 */

export default [
  {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "search" */ "../../views/search/search.vue")
  },
  // 查询到多张 门票
  {
    path: "/choice-tickets",
    name: "choice-tickets",
    meta: {
      rootUrl: "/search" // 右侧菜单根路由，用于右侧菜单高亮
    },
    component: () =>
      import(
        /* webpackChunkName: "search" */ "../../views/search/choice-tickets.vue"
      )
  },
  // 门票 详情
  {
    path: "/ticket-detail",
    name: "ticket-detail",
    meta: {
      rootUrl: "/search" // 右侧菜单根路由，用于右侧菜单高亮
    },
    component: () =>
      import(
        /* webpackChunkName: "search" */ "../../views/search/ticket-detail.vue"
      )
  }
];

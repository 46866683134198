/**
 * 门票查询版块
 */

export default [
  {
    path: "/setting",
    name: "setting",
    component: () =>
      import(
        /* webpackChunkName: "setting" */ "../../views/setting/setting.vue"
      )
  },
  {
    path: "/settingMain",
    name: "settingMain",
    meta: {
      rootUrl: "/setting" // 右侧菜单根路由，用于右侧菜单高亮
    },
    component: () =>
      import(
        /* webpackChunkName: "setting" */ "../../views/setting/setting-main.vue"
      )
  },
  {
    path: "/settingForm",
    name: "settingForm",
    meta: {
      rootUrl: "/setting" // 右侧菜单根路由，用于右侧菜单高亮
    },
    component: () =>
      import(
        /* webpackChunkName: "setting" */ "../../views/setting/setting-form.vue"
      )
  }
];

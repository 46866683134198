<!-- 
  顶部-轮播广告图
-->
<template>
  <div class="banner">
    <a-carousel
      :dots="false"
      autoplay
      :autoplay-speed="5000"
      style="height:320px;"
    >
      <template v-for="(item, index) in banner">
        <img
          v-if="item.iconUrl"
          class="item"
          :key="index"
          :src="item.iconUrl"
          @click="linkTo(item)"
        />
        <video
          height="320px"
          loop
          v-if="item.videoUrl"
          :key="index"
          :src="item.videoUrl"
          @click="linkTo(item)"
          autoplay
        ></video>
      </template>
    </a-carousel>
  </div>
</template>
<script>
import { iotSelfMachineBanner } from "@/api/iot";
export default {
  name: "Banner",
  components: {},
  props: {},
  data() {
    return {
      banner: []
    };
  },
  created() {
    iotSelfMachineBanner({
      machineSnCode: localStorage.getItem("SNCODE")
    })
      .then(res => {
        if (res && res.length) {
          this.banner = res;
        } else {
          // 没有设置banner图，使用默认的图片
          this.banner = [
            { iconUrl: require("../../assets/images/default_banner.png") }
          ];
        }
      })
      .catch(() => {});
  },
  methods: {
    linkTo(item) {
      if (item.productUrl) {
        // this.$message.success(item.productUrl);
        // window.open(item.productUrl);
        console.log(111);
      }
    }
  }
};
</script>
<style lang="less" scoped>
.item {
  height: 320px;
}
</style>

export default {
  // 更新全局loading
  updateLoading(state, status) {
    state.loading = status;
  },
  //更新会员信息
  UpdateMember(state, data) {
    state.member = data;
  },
  // 更新 支付完后，是否需要打印
  updatePrinting(state, status) {
    state.printingRequired = status;
  },
  // 是否需要打印进度弹窗
  setPrintProcess(state, data) {
    state.printProcessDialog = data;
  },

  // 设置销售商户信息
  setSaleMerchant(state, { merchantId, merchantName }) {
    state.saleMerchantId = merchantId;
    state.saleMerchantName = merchantName;
    localStorage.setItem("saleMerchantId", merchantId);
    localStorage.setItem("saleMerchantName", merchantName);
  }
};

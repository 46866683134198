<template>
  <a-config-provider class="bg-page-dark" :locale="locale" size="large">
    <router-view v-if="tokenIsOk"></router-view>
    <div class="center text-c bg-white" v-else>
      <a-icon type="loading" style="font-size: 64px" class="primary" />
      <p class="font-xl mt-2x">登录中...</p>
    </div>
  </a-config-provider>
</template>

<script>
//设置全局组件中文
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { systemInit } from "./utils/global";
// import { getMachineDetail } from "@/api/iot";
// import { mapMutations } from "vuex";
import { subScenics } from "./api/merchant";

export default {
  name: "App",
  data() {
    return {
      locale: zhCN,
      tokenIsOk: false, // 只有获取token相关的接口都调取完毕，再显示页面调取其他接口。
      menuData: [
        {
          id: "1", // id值跟景区后台设置里一致
          name: "取票",
          url: "/home",
          defaultIcon: require("./assets/images/qupiao.png"),
          activeIcon: require("./assets/images/qupiao_active.png")
        },
        {
          id: "2", // id值跟景区后台设置里一致
          name: "购票",
          url: "/buy",
          defaultIcon: require("./assets/images/goupiao.png"),
          activeIcon: require("./assets/images/goupiao_active.png")
        },
        {
          id: "3", // id值跟景区后台设置里一致
          name: "门票充值",
          url: "/recharge",
          defaultIcon: require("./assets/images/ticketcz.png"),
          activeIcon: require("./assets/images/ticketcz_active.png")
        },
        {
          id: "4", // id值跟景区后台设置里一致
          name: "项目套餐",
          url: "/project",
          defaultIcon: require("./assets/images/project.png"),
          activeIcon: require("./assets/images/project_active.png")
        },
        {
          id: "5", // id值跟景区后台设置里一致
          name: "门票查询",
          url: "/search",
          defaultIcon: require("./assets/images/ticket-search.png"),
          activeIcon: require("./assets/images/ticket-search_active.png")
        },
        {
          id: "6", // id值跟景区后台设置里一致
          name: "会员充值",
          url: "/member",
          defaultIcon: require("./assets/images/membercz.png"),
          activeIcon: require("./assets/images/membercz_active.png")
        }
      ]
    };
  },
  async created() {
    // 线上使用浏览器调试，指定SN码
    if (/SN/.test(location.search)) {
      sessionStorage.setItem("SN", location.search.substr(4));
    }
    let tokenOk = await systemInit(this);
    let subScenicOk = true;
    // 新版需要先获取子景区
    if (localStorage.newVersion === "true")
      subScenicOk = await this.subScenics();
    this.tokenIsOk = tokenOk && subScenicOk;
    // 登录完成后，首先获取自助机的配置详情数据
    // this.machineDetail();

    // let w = document.documentElement.clientWidth || document.body.clientWidth;
    // let h = document.documentElement.clientHeight || document.body.clientHeight;
    // console.log(`屏幕宽度：${w}px，屏幕高度：${h}px`);
  },
  methods: {
    // ...mapMutations("setting", ["setMachineDetail", "setMenuData"]),
    /*async machineDetail() {
      const res = await getMachineDetail(
        {
          machineSnCode: localStorage.getItem("SNCODE")
        },
        { loading: true }
      );
      this.setMachineDetail(res);
      // 根据后台的配置，保存景区后台系统设置的菜单
      const arr = res.availableFunctions?.split(",") || [];
      const newData = this.menuData.filter(item => {
        return arr.includes(item.id);
      });
      this.setMenuData(newData);
    },*/
    // 查询子景区
    async subScenics() {
      return subScenics()
        .then(({ subScenics }) => {
          // 获取本地缓存曾经激活过的子景区
          let item = subScenics.find(
            temp => temp.subScenicId === localStorage.saleMerchantId
          );
          if (!item) {
            item = subScenics[0];
            // 默认选中第一个子景区，可以通过设置切换为其它子景区
            this.$store.commit("common/setSaleMerchant", {
              merchantId: item.subScenicId,
              merchantName: item.subScenicName
            });
          }
          return true;
        })
        .catch(() => {
          this.$error({
            title: "提示",
            content: "未获取到子景区信息"
          });
          return false;
        });
    }
  }
};
</script>

<style lang="less">
@import "./assets/less/custom";
/*ant样式重写-start*/
.ant-spin-nested-loading {
  height: 100%;
}
.ant-spin-container {
  height: 100%;
}
/*tabs标签页颜色*/
.ant-tabs {
  color: @text !important;
}

// ant Message提示文字大小
.ant-message {
  font-size: 24px;
}
// ant Message提示文字 icon
.ant-message .anticon {
  font-size: 24px;
}

// Modal弹窗：$info、$success、$info、$warning方法弹窗的字体大小配置
.ant-modal-confirm-body {
  .ant-modal-confirm-title {
    font-size: 30px;
  }
  .ant-modal-confirm-content {
    font-size: 24px;
  }
  > .anticon {
    font-size: 38px;
  }
}
.ant-modal-confirm-btns {
  .ant-btn {
    width: 120px;
    height: 48px;
    font-size: 24px;
  }
}
// modal弹窗圆角
.ant-modal-content {
  border-radius: @2x;
}
/*ant样式重写-end*/

/*键盘*/
.keyboard-input-wrapper {
  &.ant-input-affix-wrapper {
    font-size: 20px;
    .ant-input {
      font-size: 20px;
      height: 60px;
      border-radius: 8px;
      &:not(:first-child) {
        padding-left: 40px;
      }
      &:not(:last-child) {
        padding-right: 40px;
      }
    }
  }
}
/*输入框前缀为文字的输入框样式*/
.prefix-input {
  .prefix {
    font-size: 20px;
  }
  .ant-input {
    height: 60px;
    padding-left: 150px !important;
    .prefix;
  }
}

/*下拉框样式重置*/
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  position: absolute;
  right: 9px;
  left: 5px;
  max-width: 100%;
  height: 28px;
  margin-top: -15px;
  line-height: 28px;
}
.ant-form-item-label {
  line-height: 50px;
}
.ant-form label {
  font-size: 22px;
}
.ant-select {
  font-size: 22px;
}
.ant-select-selection--single {
  height: 50px;
}
.ant-select-selection__rendered {
  line-height: 50px;
}
.ant-select-dropdown-menu-item {
  font-size: 22px;
  line-height: 40px;
}
</style>

export default {
  /**订单部分 */
  cartList: {}, //购物车列表,订单总价也是在这里面取
  /**出游人部分 */
  visitorsList: [], //出游人信息
  purchaserMemberInfo: {}, //购票人信息
  page: 0, //页数
  // /**整单优惠部分 */
  // merchantDiscountStr: "", //整单优惠类型（整单折扣 A）（会员折扣 B）（优惠券 C）（营销活动 D）
  // customAmount: "", //整单折扣，自定义立减或折扣时，输入的值
  // settingType: "", //整单折扣类型：1立减 2折扣
  // discountId: "", //非自定义时，整单折扣id
  // merchantDiscountAmount: 0, //订单折扣（整单优惠,会员折扣,优惠券,营销活动）抵扣了多少钱
  // couponNo: "", //优惠券号
  // marketingId: "", //优惠活动id,优惠券id
  // marketingMobile: "", //营销活动输入的会员手机号
  // idCardNo: "", //营销活动输入的身份证号
  // /**会员余额部分 */
  // // memberBalancePrice: 0, //会员余额抵扣了多少钱
  // /**会员积分部分 */
  // integralPayPrice: 0, //会员积分抵扣了多少钱
  // integralPayNum: 0, //使用了多少会员积分
  // // needToFillVisitors: {} // 需要填写的游客信息

  /**
   * 支付重构相关
   */
  // 支付重构：订单折扣优惠
  discountTextList: [],
  // 支付重构：积分抵扣优惠
  integralTextList: [],
  /**
   * 支付成功后，需要告知支付处理的配置：
   * 1、logoutMember：支付完成，是否需要退出会员登录
   * 2、backUrl：支付完后，回跳的页面url
   */
  endOfPayConfig: {
    logoutMember: true,
    backUrl: "/home"
  },
  isSubOrder: false, //是否是点了提交订单
  canSubOrder: false //是否游客信息更新完了
  //使更新购物车和下单-同步
};

<!--
@name:头部导航栏
@author: 易远胜
@props: title 标题  详情看本组件的props
@slot: 标题默认插槽
-->

<template>
  <div class="nav-bar">
    <div class="back" @click="$router.back()">
      <a-icon type="left" />
      返回
    </div>
    <div class="title flex-center">
      <slot>
        <p>{{ title }}</p>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "QmpNavBar",

  props: {
    title: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped lang="less">
.nav-bar {
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #e5e5e5;
  background: #fff;
  .back {
    width: 110px;
    height: 42px;
    position: absolute;
    left: 30px;
    top: 30px;
    border: 1px solid #9e9e9e;
    border-radius: 6px;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    color: #9e9e9e;
    &:hover {
      cursor: pointer;
    }
    &:active {
      opacity: 0.5;
    }
  }
  .title {
    height: 100%;
    font-size: 32px;
  }
}
</style>

/**
 * 罗竞旭
 * @description：
 * antd:icon风格共有三种：线框风格(outline)，实底风格(fill)，双色风格(twotone)
 *
 * @example：
 * (1):页面用到 outline 风格icon：<a-icon type="close"/>
 * 在这里引入：export { default as CloseOutline } from "@ant-design/icons/lib/outline/CloseOutline";
 *
 * (2):页面使用到 fill 风格icon: <a-icon type="setting" theme="filled" />
 * 则需引入：export { default as SettingFill } from "@ant-design/icons/lib/fill/SettingFill";
 *
 * @引入的包路径写法：
 * 如在antd文档上，找到一个icon：<a-icon type="down" />，即type为down类型的icon
 * 则，该icon类型的node_module路径为：
 * @ant-design/icons/lib/outline(icon风格)/Down(type) + Outline(风格)
 * @ant-design/icons/lib/outline/DownOutline
 */

/**
 * 用户页面，用到的icon
 */
// icon: <a-icon type="down" />
export { default as DownOutline } from "@ant-design/icons/lib/outline/DownOutline";
// <a-icon type="up" />
export { default as UpOutline } from "@ant-design/icons/lib/outline/UpOutline";
//  <a-icon type="swap" />
export { default as SwapOutline } from "@ant-design/icons/lib/outline/SwapOutline";

//  <a-icon type="setting" />
export { default as SettingOutline } from "@ant-design/icons/lib/outline/SettingOutline";

// <a-icon type="close" />
export { default as CloseOutline } from "@ant-design/icons/lib/outline/CloseOutline";

// <a-icon type="right" />
export { default as RightOutline } from "@ant-design/icons/lib/outline/RightOutline";

// <a-icon type="left" />
export { default as LeftOutline } from "@ant-design/icons/lib/outline/LeftOutline";

// <a-icon type="search" />
export { default as SearchOutline } from "@ant-design/icons/lib/outline/SearchOutline";

// <a-icon type="scan" />
export { default as ScanOutline } from "@ant-design/icons/lib/outline/ScanOutline";

// <a-icon type="check" />
export { default as CheckOutline } from "@ant-design/icons/lib/outline/CheckOutline";

// <a-icon type="plus" />
export { default as PlusOutline } from "@ant-design/icons/lib/outline/PlusOutline";

// <a-icon type="clock-circle" />
export { default as ClockCircleOutline } from "@ant-design/icons/lib/outline/ClockCircleOutline";

// <a-icon type="arrow-left" />
export { default as ArrowLeftOutline } from "@ant-design/icons/lib/outline/ArrowLeftOutline";

// <a-icon type="arrow-right" />
export { default as ArrowRightOutline } from "@ant-design/icons/lib/outline/ArrowRightOutline";

// <a-icon type="info-circle" />
export { default as InfoCircleOutline } from "@ant-design/icons/lib/outline/InfoCircleOutline";

// <a-icon type="caret-down" />
export { default as CaretDownOutline } from "@ant-design/icons/lib/outline/CaretDownOutline";

// <a-icon type="idcard" />
export { default as IdcardOutline } from "@ant-design/icons/lib/outline/IdcardOutline";

// <a-icon type="minus" />
export { default as MinusOutline } from "@ant-design/icons/lib/outline/MinusOutline";

// <a-icon type="question" />
export { default as QuestionOutline } from "@ant-design/icons/lib/outline/QuestionOutline";

// <a-icon type="reload" />
export { default as ReloadOutline } from "@ant-design/icons/lib/outline/ReloadOutline";

// <a-icon type="smile" />
export { default as SmileOutline } from "@ant-design/icons/lib/outline/SmileOutline";

// // <a-icon type="camera" />
export { default as CameraOutline } from "@ant-design/icons/lib/outline/CameraOutline";

// <a-icon type="alipay-circle" />
export { default as AlipayCircleOutline } from "@ant-design/icons/lib/outline/AlipayCircleOutline";

// <a-icon type="wechat" />
export { default as WechatOutline } from "@ant-design/icons/lib/outline/WechatOutline";

/**
 * antd组件本身用到的icon
 */
// <a-icon type="exclamation-circle" theme="filled" />  waring
export { default as ExclamationCircleFill } from "@ant-design/icons/lib/fill/ExclamationCircleFill";
// <a-icon type="check-circle" theme="filled" />  success类型icon
export { default as CheckCircleFill } from "@ant-design/icons/lib/fill/CheckCircleFill";
// <a-icon type="close-circle" theme="filled" />  error类型icon
export { default as CloseCircleFill } from "@ant-design/icons/lib/fill/CloseCircleFill";
// <a-icon type="loading"/>
export { default as LoadingOutline } from "@ant-design/icons/lib/outline/LoadingOutline";

// notification 组件用到的icon
// <a-icon type="close-circle" />  error
export { default as CloseCircleOutline } from "@ant-design/icons/lib/outline/CloseCircleOutline";
// <a-icon type="check-circle" />  success
export { default as CheckCircleOutline } from "@ant-design/icons/lib/outline/CheckCircleOutline";
// <a-icon type="exclamation-circle" /> warning
export { default as ExclamationCircleOutline } from "@ant-design/icons/lib/outline/ExclamationCircleOutline";

// Madal - $confirm icon
// <a-icon type="question-circle" />
export { default as QuestionCircleOutline } from "@ant-design/icons/lib/outline/QuestionCircleOutline";

// 返回按钮 <a-icon type="left-circle" />
export { default as LeftCircleFill } from "@ant-design/icons/lib/fill/LeftCircleFill";
// <a-icon type="right-circle" />
export { default as RightCircleFill } from "@ant-design/icons/lib/fill/RightCircleFill";
// 步进器 <a-icon type="minus-circle" />
export { default as MinusCircleFill } from "@ant-design/icons/lib/fill/MinusCircleFill";
// <a-icon type="plus-circle" theme="filled" />
export { default as PlusCircleFill } from "@ant-design/icons/lib/fill/PlusCircleFill";

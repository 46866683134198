/**
 * 购票路由
 */

export default [
  {
    path: "/buy",
    name: "buy",
    component: () =>
      import(/* webpackChunkName: "new-buy" */ "../../views/new/buy/BuyIndex")
  },
  // 支付
  {
    path: "/payment/pay",
    name: "pay",
    component: () =>
      import(/* webpackChunkName: "new-buy" */ "../../views/new/payment/PayWay")
  }
];

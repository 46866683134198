<template>
  <div>
    <!--头部轮播-->
    <Banner v-if="!isDev" />
    <!--主体页面-->
    <main class="flex-end main" :class="{ 'is-dev': isDev }">
      <!--二级路由页面-->
      <router-view class="flex-grow content"></router-view>
      <!--右侧导航栏-->
      <ul class="nav flex-shrink">
        <li
          v-for="item in menus"
          :key="item.id"
          class="item"
          :class="{ setting: item.id === '7' }"
          @click="selectItem(item.url)"
        >
          <img :src="item.icon" alt="" v-show="active !== item.url" />
          <img :src="item.activeIcon" alt="" v-show="active === item.url" />
          <p :class="{ primary: active === item.url }">{{ item.name }}</p>
        </li>
      </ul>
    </main>

    <div class="modal" v-show="loading">
      <div class="center text-c primary">
        <a-icon type="loading" style="font-size: 64px" />
        <p class="mt-2x font-xl">加载中...</p>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/common/banner";
import { isDev } from "../utils/global";
import { getMachineDetail } from "../api/iot";
import { mapMutations } from "vuex";
import { screensaver } from "../utils/screensaver";

export default {
  name: "Index",

  components: {
    Banner
  },

  data() {
    return {
      isDev, // 是否开发模式
      active: "/", // 当前激活的路由
      machineDetail: {},
      menus: []
    };
  },
  computed: {
    // 加载中状态
    loading() {
      return this.$store.state.common.loading;
    }
  },

  created() {
    this.getMachineDetail();
  },

  methods: {
    ...mapMutations("setting", ["setMachineDetail", "setMenuData"]),
    // 获取自助机配置详情
    getMachineDetail() {
      getMachineDetail({
        machineSnCode: localStorage.getItem("SNCODE")
      })
        .then(res => {
          this.machineDetail = res;
          this.setMachineDetail(res);
          this.getMenus();
        })
        .catch(() => {});
    },
    // 获取权限菜单
    getMenus() {
      const menus = [
        {
          id: "1",
          name: "取票",
          icon: require("../assets/images/new/take.png"),
          activeIcon: require("../assets/images/new/take-active.png"),
          url: "/take"
        },
        {
          id: "2",
          name: "购票",
          icon: require("../assets/images/new/buy.png"),
          activeIcon: require("../assets/images/new/buy-active.png"),
          url: "/buy"
        },
        {
          id: "6",
          name: "充值",
          icon: require("../assets/images/new/recharge.png"),
          activeIcon: require("../assets/images/new/recharge-active.png"),
          url: "/recharge"
        },
        {
          id: "5",
          name: "查询",
          icon: require("../assets/images/new/search.png"),
          activeIcon: require("../assets/images/new/search-active.png"),
          url: "/search"
        },
        {
          id: "7",
          name: "设置",
          icon: require("../assets/images/new/setting.png"),
          activeIcon: require("../assets/images/new/setting-active.png"),
          url: "/settingPrevious"
        }
      ];
      // 过滤出自助机配置的权限菜单
      if (this.machineDetail?.availableFunctions) {
        const availableFunctions = this.machineDetail.availableFunctions.split(
          ","
        );
        this.menus = menus.filter(item => availableFunctions.includes(item.id));
        // 添加阿克苏景区定制首页返回入口（坚哥需求） 2022-07-14
        if (/curstomIndex=true/.test(location.search)) {
          this.menus.splice(this.menus.length - 1, 0, {
            id: "0",
            name: "首页",
            icon: require("../assets/images/new/home.png"),
            activeIcon: require("../assets/images/new/home.png"),
            url: "/CustomIndex"
          });
        }
        // 跳转指定路由页面或第一个菜单页面
        const item =
          this.menus.find(item => item.url === this.$route.path) ||
          this.menus[0];
        this.active = item.url;
        this.$router.push(item.url);
        this.setMenuData(this.menus);
        // 是否设置了屏保时间和屏保图片
        if (
          this.machineDetail.timeOfScreenSaver &&
          this.machineDetail.pictureOfScreenSaver
        ) {
          // console.log("设置了屏保啦啦啦啦啦啦");
          const obj = {
            timeOfScreenSaver: this.machineDetail.timeOfScreenSaver,
            pictureOfScreenSaver: this.machineDetail.pictureOfScreenSaver
          };
          localStorage.setItem("screensaver", JSON.stringify(obj));
          screensaver(this);
        }
      } else {
        this.$warning({
          title: "提示",
          content:
            "该设备未获得菜单权限，请到“景区后台-设置-自助机列表管理”添加相应权限或联系管理员授权"
        });
      }
    },
    // 选中项目
    selectItem(url) {
      this.active = url;
      this.$router.replace(url);
    }
  }
};
</script>

<style scoped lang="less">
.main {
  height: calc(100vh - 320px);
  background: #f7f7f7;
  &.is-dev {
    height: 100vh;
  }
  /*左侧内容*/
  .content {
    position: relative;
    height: 100%;
    overflow: auto;
  }
  /*右侧导航栏*/
  .nav {
    position: relative;
    width: 110px;
    height: 100%;
    padding: 42px 0;
    text-align: center;
    background: #3a3a3a;
    color: #b8b8b8;
    .item {
      padding: 20px 0;
      cursor: pointer;
      > img {
        width: 36px;
        height: 36px;
        margin-bottom: 8px;
      }
    }
    .setting {
      position: absolute;
      left: 0;
      bottom: 42px;
      width: 100%;
    }
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(225, 225, 225, 0);
  z-index: 999;
}
</style>

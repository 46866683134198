<template>
  <div class="right-menu l-shadow flex-shrink">
    <ul>
      <li
        class="text-c pt-3x pointer relative"
        v-for="(item, i) in copyMenuData"
        :key="i"
        @click="menuClick(item.url, i)"
        :class="{ primary: item.url == curRoute }"
      >
        <span :class="{ triangle: item.url == curRoute }"></span>
        <img
          :src="item.url == curRoute ? item.activeIcon : item.defaultIcon"
          class="icon-img"
          alt="icon"
        />
        <p class="font-l">{{ item.name }}</p>
      </li>
    </ul>
    <div
      v-if="showSetting"
      class="setting-wrap pointer pb-3x"
      @click="goToSetting"
    >
      <a-icon :class="{ primary: curRoute == '/setting' }" type="setting" />
    </div>
  </div>
</template>
<script>
import { getMachineDetail } from "@/api/iot";
import { mapMutations, mapActions } from "vuex";
import { screensaver } from "../../utils/screensaver";

export default {
  name: "RightMenu",
  components: {},
  props: {},
  data() {
    return {
      menuData: [
        {
          id: "1", // id值跟景区后台设置里一致
          name: "取票",
          url: "/home",
          defaultIcon: require("../../assets/images/qupiao.png"),
          activeIcon: require("../../assets/images/qupiao_active.png")
        },
        {
          id: "2", // id值跟景区后台设置里一致
          name: "购票",
          url: "/buy",
          defaultIcon: require("../../assets/images/goupiao.png"),
          activeIcon: require("../../assets/images/goupiao_active.png")
        },
        {
          id: "3", // id值跟景区后台设置里一致
          name: "门票充值",
          url: "/recharge",
          defaultIcon: require("../../assets/images/ticketcz.png"),
          activeIcon: require("../../assets/images/ticketcz_active.png")
        },
        {
          id: "4", // id值跟景区后台设置里一致
          name: "项目套餐",
          url: "/project",
          defaultIcon: require("../../assets/images/project.png"),
          activeIcon: require("../../assets/images/project_active.png")
        },
        {
          id: "5", // id值跟景区后台设置里一致
          name: "门票查询",
          url: "/search",
          defaultIcon: require("../../assets/images/ticket-search.png"),
          activeIcon: require("../../assets/images/ticket-search_active.png")
        },
        {
          id: "6", // id值跟景区后台设置里一致
          name: "会员充值",
          url: "/member",
          defaultIcon: require("../../assets/images/membercz.png"),
          activeIcon: require("../../assets/images/membercz_active.png")
        }
      ],
      copyMenuData: [],
      curRoute: "/home",
      detail: {}, // 自助机详情
      showSetting: false // 是否显示
    };
  },
  watch: {
    "$route.path": {
      handler(newVal) {
        this.curRoute = newVal;
      },
      immediate: true,
      deep: true
    },
    "$route.meta": {
      handler(newVal) {
        if (newVal.rootUrl) {
          this.curRoute = newVal.rootUrl || "/home";
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.getMachineDetail();
  },
  methods: {
    ...mapMutations("setting", ["setMachineDetail", "setMenuData"]),
    ...mapActions("common", ["updateMember"]),
    // 菜单点击
    menuClick(url) {
      this.$router.push({ path: url, replace: true });
      this.getMachineDetail("refresh"); // 每次点击，都更新下菜单数据，防止后台重新设置了菜单
      this.updateMember({});
    },
    // 点击右下角 设置
    goToSetting() {
      this.$router.push({ path: "/setting", replace: true });
    },

    // 获取自助机详情: isRefresis是否是刷新菜单操作
    async getMachineDetail(isRefresis) {
      this.detail = await getMachineDetail(
        {
          machineSnCode: localStorage.getItem("SNCODE")
        },
        { loading: true }
      );
      this.setMachineDetail(this.detail);
      this.controlMenu(isRefresis);
      // 是否设置了屏保时间和屏保图片
      if (
        this.detail.timeOfScreenSaver &&
        this.detail.pictureOfScreenSaver &&
        !isRefresis
      ) {
        console.log("设置了屏保啦啦啦啦啦啦");
        const obj = {
          timeOfScreenSaver: this.detail.timeOfScreenSaver,
          pictureOfScreenSaver: this.detail.pictureOfScreenSaver
        };
        localStorage.setItem("screensaver", JSON.stringify(obj));
        screensaver(this);
      }
    },

    // 控制，是否显示：取票、购票菜单出来
    controlMenu(isRefresis) {
      // 过滤出自助机配置的权限菜单
      if (this.detail?.availableFunctions) {
        const arr = this.detail.availableFunctions.split(",");
        // 是否有设置功能
        if (arr.includes("7")) {
          this.showSetting = true;
        } else {
          this.showSetting = false;
        }

        const newData = this.menuData.filter(item => {
          return arr.includes(item.id);
        });

        this.setMenuData(newData);
        // 高亮第一个菜单
        if (!isRefresis) {
          this.curRoute = newData[0].url;
          this.$router.push(newData[0].url);
        }
        this.copyMenuData = newData;
      } else {
        this.$warning({
          title: "提示",
          content:
            "该设备未获得菜单权限，请到“景区后台-设置-自助机列表管理”添加相应权限或联系管理员授权"
        });
      }

      // // 是否
      // // 没有取票及购票功能
      // if (!arr.includes("1") && !arr.includes("2")) {
      //   this.$router.push({ path: "/recharge", replace: true });
      //   return;
      // }
      // // 显示购票 菜单
      // if (arr.includes("2")) {
      //   this.menuData.unshift({
      //     name: "购票",
      //     url: "/buy",
      //     defaultIcon: require("../../assets/images/goupiao.png"),
      //     activeIcon: require("../../assets/images/goupiao_active.png")
      //   });
      // }
      // // 没有取票功能
      // if (!arr.includes("1")) {
      //   this.$router.push({ path: "/buy", replace: true });
      //   return;
      // }

      // // 显示取票菜单
      // if (arr.includes("1")) {
      //   this.menuData.unshift({
      //     name: "取票",
      //     url: "/home",
      //     defaultIcon: require("../../assets/images/qupiao.png"),
      //     activeIcon: require("../../assets/images/qupiao_active.png")
      //   });
      // }
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";

.right-menu {
  width: @rightMenuWidth;
  height: calc(100vh - 320px); // 减去banner图高度
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #6f757f;
  font-size: 36px;
  background: #343a43;
  box-shadow: 0px 0px 14px 2px rgba(4, 0, 0, 0.06);
  li {
    height: 120px;
    box-sizing: border-box;
  }
  .icon-img {
    width: 60px;
    height: 60px;
  }
  .triangle {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-left: 14px solid #fff;
    border-bottom: 16px solid transparent;
    left: -20px;
    top: 40px;
  }
  .setting-wrap {
    font-size: 30px;
  }
}
</style>

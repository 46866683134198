import getToken from "@/utils/get-token";
import { reduceIotSelfMachineVotes, getMachineDetail } from "../api/iot";

// 环境判断
// export const isDev = process.env.NODE_ENV === "development";
export const isDev =
  process.env.NODE_ENV === "development" || !!sessionStorage.SN;

// 本地环境调试，景区后台添加的自助机SN码（登陆账号）
// export const localSN = "K216P14F00045"; //公司自助机 生态
export const localSN = sessionStorage.SN || "SN1999"; //前端本地开发调试用的自助机 beta
// export const localSN = "K213P11700058"; //前端本地开发调试用的自助机 release
// export const localSN = "HUAWEI123456"; //前端本地开发调试用的自助机
// 手机号正则匹配
export const phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;

// 保留2位小数的正则（适用于验证金额）
export const fix2Reg = /^(([1-9][0-9]*)|[0]|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;

// 正整数匹配(不包含0)
export const numberReg = /^[1-9]\d*$/;

// 0或正整数（含0）
export const integerReg = /^([1-9]\d*|[0]{1,1})$/;

// 匹配英文字母或数字
export const enOrNumberReg = /^[a-zA-Z0-9]+$/;

// 验证手机号
export const checkPhone = (rule, value, callback) => {
  if (value && !phoneReg.test(value)) {
    callback(new Error("手机号码为11位数字，不包含空格和特殊字符"));
  } else {
    callback();
  }
};

// 正向匹配合法字符-input
export const checkNormal = (rule, value, callback) => {
  let testReg = /^(?!_)(?!.*?_$)[a-zA-Z0-9_\u4e00-\u9fa5]+$/;
  if (value !== "" && !testReg.test(value)) {
    callback(
      new Error("只能包含汉字、数字、字母和下划线，不能以下划线开头和结尾")
    );
  } else {
    callback();
  }
};

// 正向匹配合法字符-textarea(含常规标点符号)
export const checkText = (rule, value, callback) => {
  let testReg = /^(?!\s)[a-zA-Z0-9\u4e00-\u9fa5`~!@%^*()_\\/\-+=<>?:"{}|,.;'[\]·！￥¥（）—《》？：“”【】、；‘，。\n\s]+$/;
  if (!value || testReg.test(value)) {
    callback();
  } else {
    callback(new Error("不能以空格开头且不能包含表情符、$、&、#等特殊符号"));
  }
};

// 匹配数字，只能为0以上的自然数(包含小数)
export const checkNumber = (rule, value, callback) => {
  const NumReg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
  !NumReg.test(value) ? callback(new Error("请输入0以上的数字")) : callback();
};
// 匹配充值金额，0以上的，两位小数内的
export const RechargeMoney = (rule, value, callback) => {
  !fix2Reg.test(value)
    ? callback(new Error("请输入数字，最多保留两位小数"))
    : value > 99999999.99
    ? callback(new Error("输入的金额不能大于99999999.99"))
    : value <= 0
    ? callback(new Error("输入的金额不能小于或等于0"))
    : callback();
};

// 匹配金额，0以上的，两位小数内的
export const checkMoney = (rule, value, callback) => {
  !fix2Reg.test(value)
    ? callback(new Error("请输入数字，最多保留两位小数"))
    : value > 99999999.99
    ? callback(new Error("输入的金额不能大于99999999.99"))
    : callback();
};

// 匹配数字，只能为0或正整数（含0）
export const checkIntegerNumber = (rule, value, callback) => {
  !value || integerReg.test(value)
    ? callback()
    : callback(new Error("请输入0 或 正整数"));
};

// 匹配英文字母或数字
export const checkEnOrNumber = (rule, value, callback) => {
  if (!enOrNumberReg.test(value)) {
    callback(new Error("仅支持英文或数字的组合"));
  } else {
    callback();
  }
};

/**
 * @name: 日期时间格式化,
 * @author: 易远胜
 * @params: {
 *   date: 时间戳或字符串时间2020-12-12 12:12:12
 *   format: 返回格式YYYY-MM-DD、YYYY-MM、YYYY-MM-DD hh:mm:ss、YYYY-MM-DD hh:mm、hh:mm:ss、hh:mm 或week(星期几)
 *           默认返回YYYY-MM-DD
 *           不区分大小写
 * }
 * @return String 2020-12-12 12:12:12
 * **/
export function dateFormat(date, format) {
  if (!date) {
    return "-";
  }
  let dateReg = /^-?[0-9]\d*$/;
  if (dateReg.test(date)) {
    date = new Date(parseInt(date));
  } else if (typeof date === "string" && "Invalid Date" != new Date(date)) {
    date = new Date(Date.parse(date.replace(/-/g, "/")));
  } else {
    return date;
  }
  function two(val) {
    if (val < 10) val = "0" + val;
    return val;
  }
  let year = date.getFullYear();
  let month = two(date.getMonth() + 1);
  let day = two(date.getDate());
  let hour = two(date.getHours());
  let minute = two(date.getMinutes());
  let second = two(date.getSeconds());
  let week = date.getDay();

  let reg1 = /^YYYY-MM-DD$/gi;
  let reg2 = /^YYYY-MM$/gi;
  let reg3 = /^YYYY-MM-DD hh:mm:ss$/gi;
  let reg4 = /^YYYY-MM-DD hh:mm$/gi;
  let reg5 = /^hh:mm:ss$/gi;
  let reg6 = /^hh:mm$/gi;
  let reg7 = /^week$/gi;
  if (!format) return `${year}-${month}-${day}`;
  if (reg1.test(format)) {
    return `${year}-${month}-${day}`;
  } else if (reg2.test(format)) {
    return `${year}-${month}`;
  } else if (reg3.test(format)) {
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  } else if (reg4.test(format)) {
    return `${year}-${month}-${day} ${hour}:${minute}`;
  } else if (reg5.test(format)) {
    return `${hour}:${minute}:${second}`;
  } else if (reg6.test(format)) {
    return `${hour}:${minute}`;
  } else if (reg7.test(format)) {
    const weeks = {
      0: "星期天",
      1: "星期一",
      2: "星期二",
      3: "星期三",
      4: "星期四",
      5: "星期五",
      6: "星期六"
    };
    return `${weeks[week]}`;
  } else {
    return "";
  }
}

/**
 * @name: 金钱格式化,
 * @author: 易远胜
 * @params: {
 *   value: "需要格式化的价格"
 * }
 * @return String ¥888
 * **/
export const moneyFormat = (value, unit = "¥") => {
  let money = Number(value);
  if (isNaN(money)) return value;
  money = money / 100; // 统一的分转为元
  // 分开小数和整数
  let valueArray = money.toString().split(".");
  // 整数部分
  let intStr = valueArray[0].toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
  // =2表示数据有小数位
  if (valueArray.length === 2) {
    let float = valueArray[1]; //小数部分
    if (float.length === 1) {
      // 补0,实际上用不着
      money = `${intStr}.${valueArray[1]}0`;
    } else {
      money = `${intStr}.${valueArray[1]}`;
    }
  } else {
    money = intStr + ".00";
  }
  return `${unit} ${money}`;
};

/**
 * @name: 验证身份证*
 * @author: 冯炎龙
 * @params: {
 *   idcard: Number
 * }
 * @return Boolean
 * */
export function isIdcard(idcard) {
  var Errors = new Array(
    "", // 验证通过!
    "身份证号码位数不对!",
    "身份证号码出生日期超出范围或含有非法字符!",
    "身份证号码校验错误!",
    "身份证地区非法!"
  );
  if (!idcard) return Errors[1];
  var area = {
    11: "北京",
    12: "天津",
    13: "河北",
    14: "山西",
    15: "内蒙古",
    21: "辽宁",
    22: "吉林",
    23: "黑龙江",
    31: "上海",
    32: "江苏",
    33: "浙江",
    34: "安徽",
    35: "福建",
    36: "江西",
    37: "山东",
    41: "河南",
    42: "湖北",
    43: "湖南",
    44: "广东",
    45: "广西",
    46: "海南",
    50: "重庆",
    51: "四川",
    52: "贵州",
    53: "云南",
    54: "西藏",
    61: "陕西",
    62: "甘肃",
    63: "青海",
    64: "宁夏",
    65: "新疆",
    71: "台湾",
    81: "香港",
    82: "澳门",
    83: "台湾", // 特殊身份证
    91: "国外"
  };

  var Y, JYM;
  var S, M, ereg;
  var idcard_array = new Array();
  idcard_array = idcard.split("");
  // 地区检验
  if (area[parseInt(idcard.substr(0, 2))] == null) return Errors[4];
  // 身份号码位数及格式检验
  switch (idcard.length) {
    // 18位身份号码检测
    case 18:
      if (
        parseInt(idcard.substr(6, 4)) % 4 == 0 ||
        (parseInt(idcard.substr(6, 4)) % 100 == 0 &&
          parseInt(idcard.substr(6, 4)) % 4 == 0)
      ) {
        ereg = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}[0-9Xx]$/; //闰年出生日期的合法性正则表达式
      } else {
        ereg = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}[0-9Xx]$/; //平年出生日期的合法性正则表达式
      }
      if (ereg.test(idcard)) {
        // 测试出生日期的合法性
        // 计算校验位
        S =
          (parseInt(idcard_array[0]) + parseInt(idcard_array[10])) * 7 +
          (parseInt(idcard_array[1]) + parseInt(idcard_array[11])) * 9 +
          (parseInt(idcard_array[2]) + parseInt(idcard_array[12])) * 10 +
          (parseInt(idcard_array[3]) + parseInt(idcard_array[13])) * 5 +
          (parseInt(idcard_array[4]) + parseInt(idcard_array[14])) * 8 +
          (parseInt(idcard_array[5]) + parseInt(idcard_array[15])) * 4 +
          (parseInt(idcard_array[6]) + parseInt(idcard_array[16])) * 2 +
          parseInt(idcard_array[7]) * 1 +
          parseInt(idcard_array[8]) * 6 +
          parseInt(idcard_array[9]) * 3;
        Y = S % 11;
        M = "F";
        JYM = "10X98765432";
        M = JYM.substr(Y, 1); // 判断校验位
        if (M == idcard_array[17]) return Errors[0];
        // 检测ID的校验位
        else return Errors[3];
      } else return Errors[2];
    // break;
    default:
      return Errors[1];
    // break;
  }
}

// 身份证的验证规则
export const checkIdCard = (rule, value, callback) => {
  if (value) {
    if (isIdcard(value)) {
      callback(new Error('身份证为15位数字 或 18位数字(最后一位可以是"X")'));
    } else {
      callback();
    }
  } else {
    callback();
  }
};

/**
 * 自助机启动初始化、及清空缓存执行的方法
 * @param {Object} vm Vue实例
 */
export async function systemInit(vm) {
  // 获取自助机的SN码
  try {
    const SNCODE = window.test.getSN(); // java后台提供的方法
    console.log(`本台自助机的SN码为：${SNCODE}`);
    if (SNCODE && !isDev) {
      localStorage.setItem("SNCODE", SNCODE);
    } else {
      localStorage.setItem("SNCODE", localSN);
    }
  } catch (error) {
    console.log(error);
    localStorage.setItem("SNCODE", localSN);
  }
  if (!localStorage.getItem("SNCODE")) {
    this.$message.warning("获取不到本机SN码，请联系开发人员");
    return;
  }
  const bool = await getToken();
  // if (String(bool) == "true") {
  if (String(bool) == "true" && !isDev) {
    let Hardware = localStorage.getItem("Hardware");
    if (Hardware === null || !Hardware) {
      vm.$router.replace("/initSetting");
    }
  }
  return bool;
}

/**
 * 取票、购票、购买项目套餐、门票补办
 * 打印门票后，更新纸张数
 * @param {Number} type: 打印纸张的类型：1门票，2项目套餐
 * @param {Number} num: 当前打印的纸张数
 * @param {Object} vm Vue实例
 */
export function updatePaper(type, num, vm) {
  const param = {
    merchantId: localStorage.getItem("merchantId"), // 商户id
    machineSnCode: localStorage.getItem("SNCODE"), // 设备sn
    remainingVotes: type == 1 ? num : 0, // 扣除剩余票数
    remainingItemVotes: type == 2 ? num : 0 // 扣除剩余项目票数
  };
  reduceIotSelfMachineVotes(param)
    .then(() => {
      // 刷新数据
      getMachineDetail({
        machineSnCode: localStorage.getItem("SNCODE")
      })
        .then(res => {
          vm.$store.commit("setting/setMachineDetail", res);
        })
        .catch(() => {});
    })
    .catch(() => {});
}

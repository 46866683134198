/**
 * MS-硬件管理
 */
import http from "../utils/fetch"; // 公共请求方法
let prefix = "/api/setting"; // api前缀

// 批量获取打印模板-刘资涌
export const getPrintTemplateList = data => {
  return http.get(`${prefix}/print/template/list`, data);
};

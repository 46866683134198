import {
  Carousel,
  Button,
  Icon,
  Row,
  Col,
  Dropdown,
  Menu,
  Pagination,
  // Cascader,
  Checkbox,
  DatePicker,
  FormModel,
  Input,
  // InputNumber,
  Radio,
  Select,
  Switch,
  // Upload,
  // Avatar,
  // Collapse,
  Empty,
  // List,
  // Popover,
  Table,
  // Tabs,
  // Tag,
  // Drawer,
  Modal,
  Spin,
  message,
  ConfigProvider,
  Steps,
  // Divider,
  Form,
  Calendar
  // Transfer
} from "ant-design-vue";
const components = [
  Carousel,
  Button,

  Icon,
  Row,
  Col,
  Dropdown,
  Menu,
  Pagination,
  // Cascader,
  Checkbox,
  DatePicker,
  FormModel,
  Input,
  // InputNumber,
  Radio,
  Select,
  Switch,
  // Upload,
  // Avatar,
  // Collapse,
  Empty,
  // List,
  // Popover,
  Table,
  // Tabs,
  // Tag,
  // Drawer,
  Modal,
  Spin,
  ConfigProvider,
  Steps,
  // Divider,
  Form,
  Calendar
  // Transfer
];
export function antd(Vue) {
  components.forEach(comp => {
    Vue.use(comp);
  });
  Vue.prototype.$confirm = parmas => {
    return Modal.confirm(configModal(parmas, "confirm"));
  };
  Vue.prototype.$info = parmas => {
    return Modal.info(configModal(parmas, "info"));
  };
  Vue.prototype.$success = parmas => {
    return Modal.success(configModal(parmas, "success"));
  };
  Vue.prototype.$error = parmas => {
    return Modal.error(configModal(parmas, "error"));
  };
  Vue.prototype.$warning = parmas => {
    return Modal.warning(configModal(parmas, "warning"));
  };
  message.config({ top: "648px" });
  Vue.prototype.$message = message;
  process.env.NODE_ENV !== "production" &&
    console.warn(
      "Antd组件是按需引入，需用到哪个组件，请添加到：antd.js文件里;\n\n 需用到的icon图标，请在antd-icons.js文件添加"
    );
}

function configModal(parmas, type) {
  const isObj = Object.prototype.toString.call(parmas) === "[object Object]";
  const isStr = Object.prototype.toString.call(parmas) === "[object String]";
  let typeStr = "this.$warning";
  switch (type) {
    case "info":
      typeStr = "this.$info";
      break;
    case "success":
      typeStr = "this.$success";
      break;
    case "error":
      typeStr = "this.$error";
      break;
    case "confirm":
      typeStr = "this.$confirm";
      break;
    default:
      break;
  }
  if (!isObj && !isStr) {
    throw `${typeStr}传参报错：1方式:直接传显示的文字内容，2方式:或者按antdv文档对象属性，自定义配置提示（默认值居中弹窗，标题：温馨提示）`;
  }

  // modal组件的静态方法弹窗：默认居中显示，标题为：温馨提示等
  const defaultObj = {
    centered: true,
    width: 580,
    class: "custom-modal",
    title: "温馨提示："
  };
  let configObj = {};
  if (isObj) {
    configObj = Object.assign(defaultObj, parmas);
  } else if (isStr) {
    configObj = Object.assign(defaultObj, {
      content: parmas
    });
  }
  return configObj;
}

<template>
  <div class="initPage">
    <div v-if="!screen" class="cont">
      <a-form-model
        :rules="rules"
        :model="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        ref="myForm"
      >
        <!-- 设置票纸质数 -->
        <a-form-model-item label="门票打印纸张数">
          <a-input
            v-model="form.ticketsInput"
            placeholder="请输入门票打印纸张数"
            addon-after="张"
            onkeyup="this.value = this.value.replace(/[^\d]/g,'')"
          />
        </a-form-model-item>
        <a-form-model-item label="项目打印纸张数">
          <a-input
            v-model="form.projectInput"
            placeholder="请输入项目打印纸张数"
            addon-after="张"
            onkeyup="this.value = this.value.replace(/[^\d]/g,'')"
          />
        </a-form-model-item>
        <!-- 门票打印模板 -->
        <a-form-model-item label="门票打印模板">
          <a-select
            placeholder="请选择门票打印模板"
            v-model="form.machinePrintTemplate"
          >
            <a-select-option
              :value="son.id"
              v-for="(son, index) in ticketTemplate"
              :key="index"
            >
              {{ son.templateName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 项目套餐打印模板 -->
        <a-form-model-item label="项目套餐打印模板" prop="projectPrintTemplate">
          <a-select
            placeholder="请选择项目套餐打印模板"
            v-model="form.projectPrintTemplate"
          >
            <a-select-option
              :value="son.id"
              v-for="(son, index) in projectTemplate"
              :key="index"
            >
              {{ son.templateName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          :label="item.labelName"
          v-for="item in options"
          :key="item.key"
          :prop="item.key"
          :rules="[
            {
              required: item.items.length > 0,
              message: '请选择',
              trigger: 'change'
            }
          ]"
        >
          <a-select
            placeholder="请选择"
            v-model="form[item.key]"
            @change="e => handleSelectChange(item.key, e)"
          >
            <a-select-option
              :value="son"
              v-for="(son, index) in item.items"
              :key="index"
            >
              {{ son }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <button
          type="button"
          class="btn ant-btn ant-btn-primary"
          @click="handleOk"
        >
          <span>保存</span>
        </button>
      </a-form-model>
    </div>
    <div v-else class="screen relative" @click="screenEnter">
      <p class="white screen-text">
        <span class="iconfont icon-click click-icon"></span>
        点击屏幕，进入操作页面
      </p>
      <img :src="screenImg" alt="屏保" />
    </div>
  </div>
</template>

<script>
import { getPrintTemplateList } from "@/api/setting";
import { updateMachineVotes } from "@/api/iot";
import { mapGetters, mapMutations } from "vuex";
import { Modal } from "ant-design-vue";

export default {
  name: "init",
  data() {
    return {
      options: [],
      rules: {
        projectPrintTemplate: [
          { required: true, message: "请选择", trigger: "change" }
        ]
      },
      form: {
        ticketsInput: "",
        projectInput: "",
        machinePrintTemplate: "", // 门票打印模板
        projectPrintTemplate: "" // 项目套餐打印模板
      },
      ticketTemplate: [], // 门票打印模板数据
      projectTemplate: [], // 项目套餐打印模板
      screen: "",
      screenImg: ""
    };
  },
  computed: {
    ...mapGetters("setting", ["machineDetail", "menuData"])
  },
  created() {
    // 是否屏保
    this.screen = this.$route.query.screen || "";
    if (!this.screen) {
      this.hardwareInit();
    } else {
      // 屏保
      const obj = localStorage.getItem("screensaver");
      this.screenImg = JSON.parse(obj).pictureOfScreenSaver;
    }
  },
  methods: {
    ...mapMutations("setting", ["setMachineDetail"]),
    handleOk() {
      this.$refs.myForm.validate(valid => {
        if (valid) {
          this.savePaper(); // 保存纸张数
          try {
            window.test.choiceDevice(JSON.stringify(this.form));
            localStorage.setItem("Hardware", JSON.stringify(this.form));
            // console.log("菜单" , this.menuData[0]);
            // const url = this.menuData[0].url;
            // this.$router.replace(url);
            // 如果curstomIndex=true 则跳转阿克苏自定义首页
            let path = /curstomIndex=true/.test(location.search)
              ? "/CustomIndex"
              : "/";
            this.$router.replace(path);
          } catch (error) {
            console.log(error);
            this.$error("初始化失败");
          }
        } else {
          console.log("提交失败");
          return false;
        }
      });
    },
    handleSelectChange(key, value) {
      this.form[key] = value;
    },
    // 保存纸张数
    savePaper() {
      // 有填写了数据，才调接口
      if (this.form.ticketsInput && this.form.projectInput) {
        updateMachineVotes({
          machineSnCode: localStorage.getItem("SNCODE"),
          remainingVotes: this.form.ticketsInput,
          remainingItemVotes: this.form.projectInput
        })
          .then(() => {
            this.setMachineDetail(
              Object.assign(this.machineDetail, {
                remainingVotes: this.form.ticketsInput,
                remainingItemVotes: this.form.projectInput
              })
            );
          })
          .catch(() => {});
      }
    },
    // 硬件数据获取
    hardwareInit() {
      let me = this;
      let Hardware = localStorage.getItem("Hardware");
      if (window.test) {
        try {
          let options = window.test.initDevice();
          const opt = JSON.parse(options);
          console.log("硬件数据", opt);
          // 去掉交账打印机数据：后端接口不愿意处理(有些打印机是窗口端项目需要，自助机不需要，后端全部一起返回来)
          /**
           * accountPrintNames:交账打印机
           * smallPrinterNames:收银小票打印机
           * consumeRecordPrinterNames:消费记录打印机;
           * callSmallPrintNames:叫号小票打印机;
           */
          const arr = [
            "accountPrintNames",
            "smallPrinterNames",
            "consumeRecordPrinterNames",
            "callSmallPrintNames"
          ];
          const newData = opt.filter(item => {
            return !arr.includes(item.key);
          });
          me.options = newData;
          me.getPrintTemplateList(5); // 获取项目套餐打印模板
          me.getPrintTemplateList(1); // 获取门票打印模板
          //表单渲染完成，如果有旧的缓存配置，把旧的缓存配置更新到这里
          if (Hardware) {
            me.form = JSON.parse(Hardware);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async getPrintTemplateList(type) {
      const res = await getPrintTemplateList({
        templateType: type // 1、门票打印模板类型，5项目套餐打印模板类型
      });
      if (type == 1) {
        res.unshift({
          id: "",
          templateName: "无"
        });
        this.ticketTemplate = res;
      } else {
        this.projectTemplate = res;
      }
    },

    // 点击屏保
    screenEnter() {
      Modal.destroyAll();
      const url = this.menuData[0].url;
      this.$router.push({ path: url, replace: true });
    }
  }
};
</script>

<style lang="less" scoped>
.initPage {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  .ant-form {
    width: 90%;
  }
  // /deep/.ant-form-item {
  //   margin-top: 10px;
  // }
  /deep/.ant-input {
    height: 50px;
    line-height: 50px;
    font-size: 22px;
  }
  .cont {
    background: #fff;
    border-radius: 10px;
    width: 80%;
    min-height: 50%;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 20px;
  }

  // 屏保
  .screen {
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .screen-text {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    color: #f56c6c;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
  }
  .click-icon {
    font-size: 50px;
  }
}
</style>

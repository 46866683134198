/**
 * 设置路由
 */

export default [
  {
    path: "/setting",
    name: "SettingIndex",
    component: () =>
      import(
        /* webpackChunkName: "new-setting" */ "../../views/new/setting/SettingIndex"
      )
  },
  {
    path: "/settingPrevious",
    name: "SettingPrevious",
    component: () =>
      import(
        /* webpackChunkName: "new-setting" */ "../../views/new/setting/SettingPrevious"
      )
  },
  {
    path: "/settingForm",
    name: "settingForm",
    component: () =>
      import(
        /* webpackChunkName: "new-setting" */ "../../views/setting/setting-form.vue"
      )
  },
  {
    path: "/switchScenic",
    name: "SwitchScenic",
    component: () =>
      import(
        /* webpackChunkName: "new-setting" */ "../../views/new/setting/SwitchScenic"
      )
  }
];

/**
 * @name:【新版merchant】MS-商户服务【/api/merchant】
 **/

import http from "../utils/fetch";
const prefix = "/api/merchant";

/**
 * 商户信息
 */
// 【user022】-获取当前用户所属商户列表-云玉峰
export function findCurrentUserRelationMerchantList() {
  return http.get(`${prefix}/find/current/user/relation/merchant/list`);
}

// 查询子景区列表-刘院民
export function subScenics(data) {
  return http.get(`${prefix}/merchant/of/sub/scenic/tree`, data);
}

// 通过商户获取 分时预约 + 订单信息采集(不需要传入商户id) http://192.168.1.50:40001/project/41/interface/api/3881
export function merchantSettingOrderInfo(data) {
  return http.get(`${prefix}/merchant/setting/order/info`, data);
}
